@if (isStandalone$ | ngrxPush) {
  <schaeffler-app-shell
    [hasSidebarLeft]="true"
    [appTitle]="'appTitle' | transloco"
    [appTitleLink]="'/home'"
    [footerFixed]="false"
    [footerLinks]="footerLinks$ | ngrxPush"
    (onContentScroll)="containerScrollEvent$.next($event)"
  >
    <ng-container mainContent>
      <schaeffler-banner></schaeffler-banner>
      <div class="border-t border-border"></div>
      <router-outlet></router-outlet>
      <div
        *transloco="let t; read: 'legal'"
        class="mx-auto w-full max-w-2xl bg-surface"
        id="cookie-settings"
        [ngClass]="{ hidden: !isCookiePage }"
      >
        <div class="py-3 px-4">
          <button id="ot-sdk-btn" class="ot-sdk-show-settings">
            {{ t('cookieSettings') }}
          </button>
        </div>
        <div class="py-3 md:px-4">
          <div id="ot-sdk-cookie-policy"></div>
        </div>
      </div>
    </ng-container>
    <ng-container sidenavBody>
      <div class="px-5 py-4">
        <ea-settings-panel></ea-settings-panel>
      </div>
    </ng-container>
  </schaeffler-app-shell>
} @else {
  @if (isBearingSupported$ | ngrxPush) {
    <ng-container *ngTemplateOutlet="appInner"></ng-container>
  } @else {
    <schaeffler-banner></schaeffler-banner>
    <ea-legacy-app [bearingDesignation]="bearingDesignation"> </ea-legacy-app>
  }
}

<ng-template #appInner>
  <!-- The max-w class forces the app to not overflow in medias. Paddings are taken from medias. -->
  <schaeffler-banner></schaeffler-banner>
  <div
    class="flex h-full w-full flex-1 flex-col items-center relative !max-w-[calc(100vw-32px-40px-2px)]"
  >
    <div class="md:flex md:flex-1 max-w-full">
      <ea-calculation-container class="max-w-full"></ea-calculation-container>
    </div>
  </div>
</ng-template>
