/**
 * ISO VG Class mapping with label and viscosity as value
 */
export const ISOVgClasses = [
  { label: 'ISO VG 10', value: 10 },
  { label: 'ISO VG 15', value: 15 },
  { label: 'ISO VG 22', value: 22 },
  { label: 'ISO VG 32', value: 32 },
  { label: 'ISO VG 46', value: 46 },
  { label: 'ISO VG 68', value: 68 },
  { label: 'ISO VG 100', value: 100 },
  { label: 'ISO VG 150', value: 150 },
  { label: 'ISO VG 220', value: 220 },
  { label: 'ISO VG 320', value: 320 },
  { label: 'ISO VG 460', value: 460 },
  { label: 'ISO VG 680', value: 680 },
  { label: 'ISO VG 1000', value: 1000 },
  { label: 'ISO VG 1500', value: 1500 },
];
