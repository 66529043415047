import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, of, switchMap, takeUntil } from 'rxjs';

import { DownstreamCalculationService } from '@ea/core/services/downstream-calculation.service';
import { DownstreamAPIRequest } from '@ea/core/services/downstream-calculation.service.interface';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

import { CO2DownstreamCalculationActions } from '../../actions';
import {
  CalculationParametersFacade,
  ProductSelectionFacade,
} from '../../facades';

/*
const _calculationRequest: DownstreamAPIRequest = {
  operatingConditions: {
    iD_OPERATING_TIME_IN_HOURS: 24,
    idL_LUBRICATION_METHOD: "LB_OIL_BATH_LUBRICATION",
    idslC_TEMPERATURE: 24,
    idL_NY_40: 20,
    idL_NY_100: 50,
    IDL_INFLUENCE_OF_AMBIENT: "LB_AVERAGE_AMBIENT_INFLUENCE",
    IDL_CLEANESS_VALUE: "LB_STANDARD_CLEANLINESS",
    IDL_CONDITION_OF_ROTATION: "LB_ROTATING_INNERRING",
    idL_DEFINITION_OF_VISCOSITY: "LB_ISO_VG_CLASS",
    IDL_GREASE: "LB_PLEASE_SELECT",
    IDL_ISO_VG_CLASS: "LB_ISO_VG_10",
    idscO_CO2_EMISSION_FACTOR_ELECTRICITY_REGIONAL: "LB_EUROPEAN_UNION",
    idscO_CO2_EMISSION_FACTOR_CALCULATION: "LB_ELECTRIC_ENERGY"
  },
  loadcaseData: [
    {
      idcO_DESIGNATION: "loadcase 1",
      idslC_TIME_PORTION: 100,
      idslC_AXIAL_LOAD: 600,
      idslC_RADIAL_LOAD: 100,
      idslC_MEAN_BEARING_OPERATING_TEMPERATURE: 20,
      idlC_SPEED: 250,
      idslC_TYPE_OF_MOVEMENT: "LB_ROTATING"
    }
  ]
}
*/

@Injectable()
export class DownstreamCalculationEffect {
  public fetchDownstreamCalculation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CO2DownstreamCalculationActions.fetchDownstreamCalculation),
      concatLatestFrom(() => [
        this.productSelectionFacade.bearingDesignation$,
        this.calculationParametersFacade.getLoadcases$,
        this.calculationParametersFacade.operationConditions$,
      ]),
      switchMap(([_action, _designation, loadcases, operatingConditions]) => {
        const calcRequest: DownstreamAPIRequest = {
          operatingConditions: {
            IDL_CLEANESS_VALUE: operatingConditions.contamination,
            idscO_CO2_EMISSION_FACTOR_ELECTRICITY_REGIONAL: 'LB_EUROPEAN_UNION',
            idscO_CO2_EMISSION_FACTOR_CALCULATION: 'LB_ELECTRIC_ENERGY',
            iD_OPERATING_TIME_IN_HOURS: 24,
            idL_LUBRICATION_METHOD: 'LB_OIL_BATH_LUBRICATION',
            idslC_TEMPERATURE: 24,
            idL_NY_40: 20,
            idL_NY_100: 50,
            IDL_INFLUENCE_OF_AMBIENT: 'LB_AVERAGE_AMBIENT_INFLUENCE',
            IDL_CONDITION_OF_ROTATION: 'LB_ROTATING_INNERRING',
            idL_DEFINITION_OF_VISCOSITY: 'LB_ISO_VG_CLASS',
            IDL_GREASE: 'LB_PLEASE_SELECT',
            IDL_ISO_VG_CLASS: 'LB_ISO_VG_10',
          },
          loadcaseData: loadcases.flatMap((lc) => {
            const conditions = operatingConditions.loadCaseData[lc.index];

            return {
              idcO_DESIGNATION: lc.loadCaseName,
              idlC_SPEED: 250,
              idslC_TIME_PORTION: conditions.operatingTime,
              idslC_AXIAL_LOAD: conditions.load.axialLoad || 0,
              idslC_RADIAL_LOAD: conditions.load.radialLoad || 0,
              idslC_TYPE_OF_MOVEMENT: conditions.rotation.typeOfMotion,
              idslC_MEAN_BEARING_OPERATING_TEMPERATURE: 20,
            };
          }),
        };

        return this.downstreamService
          .getDownstreamCalculation('320/22-X', calcRequest)
          .pipe(
            takeUntil(
              this.actions$.pipe(
                ofType(
                  CO2DownstreamCalculationActions.fetchDownstreamCalculation
                )
              )
            ),
            switchMap((result) => {
              console.log('result', result);

              return of(
                CO2DownstreamCalculationActions.setDownstreamCalculationResult({
                  result,
                })
              );
            }),
            catchError((error: HttpErrorResponse) => {
              console.log('Error from downstrema calculation api', error);

              return of(
                CO2DownstreamCalculationActions.resetDownstreamCalculation()
              );
            })
          );
      })
    )
  );

  constructor(
    private readonly downstreamService: DownstreamCalculationService,
    private readonly actions$: Actions,
    private readonly productSelectionFacade: ProductSelectionFacade,
    private readonly calculationParametersFacade: CalculationParametersFacade
  ) {}
}
