export const NON_CO2_BEARINGS = [
  '30224-A',
  '6000-C-2BRS-C3',
  '6000-C-2HRS-L038',
  '6000-C-2HRS-L059/50',
  '6000-C-2HRS-L408/20-R15-23',
  '6000-C-2HRS-TVH-C3',
  '6000-C-2Z-L069',
  '6000-C-2Z-L091-R6-14',
  '6000-C-2Z-L138-R15-23',
  '6000-C-2Z-L210/50-R8-13-NMB',
  '6000-C-TVH-UNS',
  '6000-C-Z-C3',
  '6001-C-2BRS-P5-L207/50-R8-13-NMB',
  '6001-C-2BRS-TVH-L074-C3',
  '6001-C-2HRS-L069',
  '6001-C-2HRS-L138',
  '6001-C-2HRS-L207',
  '6001-C-2HRS-L207-C3',
  '6001-C-2Z-L038',
  '6001-C-2Z-L235-R20-30',
  '6001-C-2Z-L235-R20-30-NMB',
  '6001-C-2Z-P5-LC729-R20-30-NMB',
  '6001-C-Z-C4',
  '6002-C-2HRS-L207',
  '6002-C-2HRS-P5-C4',
  '6002-C-2Z-L138',
  '6002-C-2Z-L237-C3',
  '6004-C-2BRS-L091-C3',
  '6004-C-2BRS-P5-L069-C3',
  '6004-C-2HRS-L138-C3',
  '6004-C-2HRS-L138-CM',
  '6004-C-2Z-L069-C3',
  '6004-C-2Z-L138',
  '6004-C-2Z-L207-C3',
  '6004-C-TVH-C3',
  '61803-P6-DF-HLC',
  '61820-Y-P6-O-AVK',
  '6200-C-2HRS-L038',
  '6200-C-2HRS-L091-C3',
  '6200-C-2Z-L038-C3',
  '6201-C-2HRS-L059/50',
  '6201-C-2HRS-L069-C3',
  '6201-C-2HRS-L138-CM',
  '6201-C-2Z-S1-L207-C4',
  '6201-C-J11-C3',
  '6201-C-R11-18-UNS',
  '6202-C-2BRS-L091-C3',
  '6202-C-2BRS-L138-C3',
  '6202-C-2HRS-FKM-L100/48',
  '6202-C-2HRS-FKM-S1-J11-L077-C4',
  '6202-C-2HRS-TVH-L138-R4-11',
  '6202-C-2Z-C2',
  '6202-C-2Z-L038-C3',
  '6202-C-2Z-L207-C3',
  '6202-C-C3-UNS',
  '6202-C-R5-13',
  '6202-C-R9-17-UNS',
  '6202-C-TVH-C4',
  '6202-C-TVH-R17-26-UNS',
  '6202-C-TVH-R9-17-UNS',
  '6203-C-2BRS-L019-C3',
  '6203-C-2BRS-L091-C3',
  '6203-C-2HRS-FKM-S1-L197-C4',
  '6203-C-2HRS-L207-C3',
  '6203-C-R13-22-UNS',
  '6203-C-TVH-J11-S0-C3',
  '6204-C-2HRS-C4',
  '6204-C-2HRS-L100-C3',
  '6204-C-2HRS-L138',
  '6204-C-2HRS-L140-C3',
  '6204-C-2HRS-L207-C3',
  '6204-C-2Z-L038',
  '6204-C-2Z-L038-C3',
  '6205-C-2BRS-L038-C3',
  '6205-C-2HRS-HNBR',
  '6205-C-2HRS-L059/50',
  '6205-C-2HRS-L059/50-C3',
  '6205-C-2HRS-L138',
  '6205-C-2HRS-L550',
  '6205-C-2Z-CM',
  '6205-C-2Z-L038',
  '6205-C-2Z-L207',
  '6205-C-2Z-L460',
  '6205-C-P5',
  '6205-C-P5-C2',
  '6205-C-TVH-C4',
  '6206-C-2Z-L069',
  '6206-C-2Z-L138-CM',
  '629-C-2HRS-L038-C3',
  '629-C-2Z-L038-C3',
  '629-C-C3',
  '6304-2RSR-L271/40',
  '694-2Z-HLC-C3',
  'HC6004-C-2HRS-TVH-L207-C3',
  'HC6205-C-2HRS-TVH-L207-C3',
  'HCB71919-E-2RSD-T-P4S-UL',
  'HCB7211-C-2RSD-T-P4S-UL',
  'HCB7226-E-T-P4S-UL',
  'HCB7228-E-T-P4S-UL',
  'HCS7000-E-T-P4S-UL',
  'NK50/25-TV-P5-XL',
  'PCJ55-XL-I',
  'RCJ65-214-XL-FA164-I',
  'RCJL60-XL-N-I',
  'RCJT50-XL-FA164-I',
  'RCJTZL25-I',
  'RCJTZL60-I',
  'SL192305-XL',
  'SL192310-XL',
  'SL192352-TB-BR',
];
