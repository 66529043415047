import { DialogModule, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { map } from 'rxjs';

import { PDFReportService } from '@ea/core/services/pdf-report.service';
import { TrackingService } from '@ea/core/services/tracking-service/tracking.service';
import {
  CalculationParametersFacade,
  CalculationResultFacade,
  SettingsFacade,
} from '@ea/core/store';
import { ProductSelectionFacade } from '@ea/core/store/facades/product-selection/product-selection.facade';
import { CalculationParametersCalculationTypeConfig } from '@ea/core/store/models';
import { ExpansionPanelComponent } from '@ea/shared/expansion-panel/expansion-panel.component';
import { InfoBannerComponent } from '@ea/shared/info-banner/info-banner.component';
import { MeaningfulRoundPipe } from '@ea/shared/pipes/meaningful-round.pipe';
import { QualtricsInfoBannerComponent } from '@ea/shared/qualtrics-info-banner/qualtrics-info-banner.component';
import { TagComponent } from '@ea/shared/tag/tag.component';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { TranslocoDecimalPipe } from '@jsverse/transloco-locale';
import { LetDirective, PushPipe } from '@ngrx/component';

import { SharedTranslocoModule } from '@schaeffler/transloco';

import { CalculationDisclaimerComponent } from '../calculation-disclaimer/calculation-disclaimer.component';
import { CalculationResultMessagesComponent } from '../calculation-result-messages/calculation-result-messages.component';
import { CalculationResultReportInputComponent } from '../calculation-result-report-input';
import { CalculationResultReportLargeItemsComponent } from '../calculation-result-report-large-items/calculation-result-report-large-items.component';
import { CalculationResultReportSelectionComponent } from '../calculation-result-report-selection/calculation-result-report-selection.component';
import { CalculationTypesSelectionComponent } from '../calculation-types-selection/calculation-types-selection.component';

@Component({
  templateUrl: './calculation-result-report.component.html',
  standalone: true,
  imports: [
    CommonModule,
    PushPipe,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    SharedTranslocoModule,
    TagComponent,
    LetDirective,
    MeaningfulRoundPipe,
    TranslocoModule,
    CalculationTypesSelectionComponent,
    CalculationResultReportInputComponent,
    CalculationResultMessagesComponent,
    InfoBannerComponent,
    ExpansionPanelComponent,
    CalculationResultReportLargeItemsComponent,
    QualtricsInfoBannerComponent,
    DialogModule,
    CalculationResultReportSelectionComponent,
  ],
  providers: [TranslocoDecimalPipe, MeaningfulRoundPipe],
})
export class CalculationResultReportComponent {
  public co2ResultItem$ =
    this.calculationResultFacade.calculationReportCO2Emission$.pipe(
      map((result) => {
        const unit = this.translocoSevice.translate(
          'calculationResultReport.co2Emissions.unit'
        );

        const productionTooltip = this.translocoSevice.translate(
          'calculationResult.productionTooltip'
        );

        return [
          {
            value: result.co2_upstream,
            unit: 'kg',
            short: unit,
            title: 'upstreamTitle',
            titleTooltip: productionTooltip,
          },
        ];
      })
    );

  public bearingDesignation$ = this.productSelectionFacade.bearingDesignation$;
  constructor(
    public readonly calculationResultFacade: CalculationResultFacade,
    public readonly productSelectionFacade: ProductSelectionFacade,
    public readonly calculationParametersFacade: CalculationParametersFacade,
    public readonly dialogRef: DialogRef<CalculationResultReportComponent>,
    public readonly settingsFacade: SettingsFacade,
    private readonly dialog: MatDialog,
    private readonly translocoSevice: TranslocoService,
    private readonly trackingService: TrackingService,
    private readonly reportService: PDFReportService
  ) {}

  closeDialog() {
    this.dialogRef.close();
  }

  showCalculationDisclaimerDialog() {
    this.dialog.open(CalculationDisclaimerComponent, {
      hasBackdrop: true,
      autoFocus: true,
      maxWidth: '750px',
    });
  }

  scrollIntoView(itemName: CalculationParametersCalculationTypeConfig['name']) {
    const scrollOptions: ScrollIntoViewOptions = {
      behavior: 'smooth',
      block: 'start',
    };
    document.querySelector(`#${itemName}`)?.scrollIntoView(scrollOptions);
  }

  async downloadPdfReport() {
    this.trackingService.logDownloadReport();
    const report = await this.reportService.generate();
    const reportName = await this.reportService.generateFilename();
    report.document.save(reportName);
  }
}
