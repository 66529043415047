<ng-container
  *transloco="let t; read: 'calculationResultReport.overrollingFrequencies'"
>
  @if (_item) {
    <div
      class="flex flex-shrink-0 flex-col items-center justify-between disabled-border border-border min-h-[6rem] md:border-0 md:w-auto pt-2 h-full disabled-bg-surface md:bg-transparent rounded-lg overflow-y-hidden"
      [ngClass]="styleClass"
    >
      <div class="flex items-center mx-4">
        <mat-icon
          [svgIcon]="_item.svgIcon"
          color="primary"
          class="flex-shrink-0"
        >
          {{ _item.icon }}
        </mat-icon>
        <div
          class="text-caption text-medium-emphasis flex-shrink-0 font-medium"
        >
          {{ t('title') }}
        </div>
      </div>
      @if (isLoading$ | ngrxPush) {
        <div class="flex items-center justify-center h-full">
          <mat-spinner diameter="16"></mat-spinner>
        </div>
      } @else {
        @if (resultUnavailable$ | ngrxPush) {
          <p>-</p>
        }
        @if (!_item?.values?.[0]?.calculationError) {
          <div class="relative min-w-full data-items">
            @for (value of animationItems$ | ngrxPush; track value) {
              @if (value.animationState === 'active') {
                <div
                  class="flex flex-row items-center space-x-2 absolute inset-x-0 -translate-y-1/2"
                  [@enterExitAnimation]
                  data-testid="data-value"
                >
                  <div class="text-caption text-medium-emphasis flex-1">
                    {{ t(value.value.title) }}
                  </div>
                  <div class="flex items-center h-full shrink-0">
                    {{ value.value.value | meaningfulRound }}
                    {{ value.value.unit }}
                  </div>
                </div>
              }
            }
          </div>
          <div
            class="indicators flex flex-row justify-around space-x-1 min-w-full self-end"
            data-testid="paging-container"
          >
            @for (item of dataFields$ | ngrxPush; track item; let i = $index) {
              <div
                class="block h-[2px] transition-all duration-1000 ease-in-out w-1/4"
                [ngClass]="{
                  'cursor-pointer hover:scale-y-110': clickablePaging,
                  'bg-primary w-1/3': i === (currentIndex$ | ngrxPush),
                  'bg-inactive w-1/4': i !== (currentIndex$ | ngrxPush)
                }"
                (click)="selectIndex(i)"
              ></div>
            }
          </div>
        } @else {
          <div
            class="flex flex-shrink-0 flex-grow items-center"
            [matTooltip]="_item?.values?.[0]?.calculationError"
          >
            <mat-icon color="error" class="!text-body-2 mx-auto"
              >warning</mat-icon
            >
          </div>
        }
      }
      @if (_item.loadcaseName && _item.loadcaseName !== '') {
        <div class="text-caption text-medium-emphasis py-2">
          {{ _item.loadcaseName }}
        </div>
      }
      <ng-template #error>
        <div
          class="flex flex-shrink-0 flex-grow items-center"
          [matTooltip]="_item?.values?.[0]?.calculationError"
        >
          <mat-icon color="error" class="!text-body-2 mx-auto"
            >warning</mat-icon
          >
        </div>
      </ng-template>
    </div>
  }
</ng-container>
