<div
  class="flex w-full flex-row items-center justify-center rounded-md border-[1px] py-2 px-3"
  [ngClass]="{
    'border-info bg-info': !isWarning,
    'border-warning bg-warning': isWarning
  }"
>
  @if (!isWarning) {
    <mat-icon class="mr-4 align-middle flex-shrink-0 text-icon-info"
      >info_outline
    </mat-icon>
  }
  @if (isWarning) {
    <mat-icon class="mr-4 align-middle flex-shrink-0 text-icon-warning"
      >warning_amber
    </mat-icon>
  }

  <div
    class="text-subtitle-2"
    [ngClass]="{
      'text-info': !isWarning,
      'text-warning': isWarning
    }"
  >
    {{ infoText }}
    <ng-content></ng-content>
  </div>
</div>
