import { Injectable } from '@angular/core';

import { Action, Store } from '@ngrx/store';

import { getCalculationFieldsConfig } from '../../selectors/calculation-parameters/calculation-fields.selector';
import {
  getLoadcaseCount,
  getLoadcases,
  getOperationConditions,
  getSelectedLoadcase,
  getSelectedLoadcaseId,
  isCalculationMissingInput,
} from '../../selectors/calculation-parameters/calculation-parameters.selector';
import {
  getCalculationTypes,
  getCalculationTypesConfig,
  getCalculationTypesGlobalSelectionState,
  hasCalculationsSelected,
} from '../../selectors/calculation-parameters/calculation-types.selector';
import { isAnyServiceLoading } from '../../selectors/calculation-result/calculation-result-preview.selector';

@Injectable({
  providedIn: 'root',
})
export class CalculationParametersFacade {
  public readonly operationConditions$ = this.store.select(
    getOperationConditions
  );

  public isCalculationMissingInput$ = this.store.select(
    isCalculationMissingInput
  );

  public getCalculationTypesConfig$ = this.store.select(
    getCalculationTypesConfig
  );

  public getCalculationTypes$ = this.store.select(getCalculationTypes);

  public hasCalculation$ = this.store.select(hasCalculationsSelected);

  public getCalculationTypesGlobalSelection$ = this.store.select(
    getCalculationTypesGlobalSelectionState
  );

  public getCalculationFieldsConfig$ = this.store.select(
    getCalculationFieldsConfig
  );

  public isAnyServiceLoading$ = this.store.select(isAnyServiceLoading);

  public getSelectedLoadcaseId$ = this.store.select(getSelectedLoadcaseId);
  public getLoadcaseCount$ = this.store.select(getLoadcaseCount);
  public getLoadcases$ = this.store.select(getLoadcases);
  public getSelectedLoadcase$ = this.store.select(getSelectedLoadcase);

  constructor(private readonly store: Store) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }
}
