<ng-container *transloco="let t; read: 'calculationResult'">
  @if (_item) {
    <div
      class="flex flex-shrink-0 flex-col items-center justify-between md:justify-center disabled-border border-border md:border-0 md:w-auto pt-2 md:h-auto h-full disabled-bg-surface md:bg-transparent rounded-lg"
      [ngClass]="styleClass"
    >
      <div class="flex flex-shrink-0 flex-row items-center justify-center px-2">
        <mat-icon
          color="primary"
          [svgIcon]="_item.svgIcon"
          class="mr-1 flex-shrink-0"
        >
          {{ _item.icon }}
        </mat-icon>
        <div
          class="text-caption text-medium-emphasis inline-flex flex-shrink-0 font-medium"
        >
          {{ t(_item.title) }}
          @if (_item.titleTooltip) {
            <ea-info-button
              class="ml-1"
              [inline]="true"
              [tooltip]="t(_item.titleTooltip)"
            ></ea-info-button>
          }
        </div>
      </div>
      <div class="flex flex-row">
        @for (value of _item.values; track value; let last = $last) {
          <div
            class="flex flex-col md:pt-2 px-1 sm:px-4"
            [class.items-center]="isSingleItem"
          >
            <div
              class="text-caption text-medium-emphasis mb-2"
              [class.text-center]="isSingleItem"
            >
              {{ value.title && t(value.title) }}
              @if (value.titleTooltip) {
                <ea-info-button
                  class="inline-flex align-bottom"
                  [inline]="true"
                  [tooltip]="t(value.titleTooltip)"
                ></ea-info-button>
              }
            </div>
            @if (!value.isLoading) {
              <div class="flex flex-row items-end justify-start">
                @if (!value.calculationError && !value.calculationWarning) {
                  <div class="text-body-2 text-high-emphasis">
                    {{ (value.value | meaningfulRound) || '-' }}
                  </div>
                  @if (value.unit) {
                    <div class="text-body-2 text-high-emphasis pl-1">
                      {{ value.unit }}
                    </div>
                  }
                  <ng-content></ng-content>
                } @else {
                  @if (value.calculationError) {
                    <ng-container [ngTemplateOutlet]="error"> </ng-container>
                  } @else {
                    <div class="flex items-center">
                      <mat-icon
                        class="mr-2 align-middle flex-shrink-0 text-icon-info"
                        >info_outline
                      </mat-icon>
                      <div
                        class="text-caption text-medium-emphasis md:max-w-[12rem] md:text-left text-center"
                      >
                        {{ t(value.calculationWarning) }}
                      </div>
                    </div>
                  }
                }
                <ng-template #error>
                  <div
                    class="flex flex-shrink-0 flex-grow"
                    [matTooltip]="value.calculationError"
                  >
                    <mat-icon color="error" class="!text-body-2 mx-auto"
                      >warning</mat-icon
                    >
                  </div>
                </ng-template>
                <ng-template #warning>
                  <div class="flex items-center">
                    <mat-icon
                      class="mr-2 align-middle flex-shrink-0 text-icon-info"
                      >info_outline
                    </mat-icon>
                    <div
                      class="text-caption text-medium-emphasis md:max-w-[12rem] md:text-left text-center"
                    >
                      {{ t(value.calculationWarning) }}
                    </div>
                  </div>
                </ng-template>
              </div>
            } @else {
              <div class="flex items-center justify-center">
                <mat-spinner diameter="16"></mat-spinner>
              </div>
            }
            @if (_item.loadcaseName && _item.loadcaseName !== '') {
              <div class="text-caption text-medium-emphasis py-2">
                {{ _item.loadcaseName }}
              </div>
            }
          </div>
          @if (!last) {
            <mat-divider [vertical]="true"></mat-divider>
          }
        }
      </div>
    </div>
  }
</ng-container>
