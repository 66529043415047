<div
  class="md:flex md:flex-row md:mb-14 mb-64 overflow-hidden justify-center"
  [ngClass]="{
    'mt-6 mb:pb-0 p-2 md:p-0': isStandalone$ | ngrxPush,
    'mb-64': isResultPreviewSticky$ | ngrxPush
  }"
  #wrapperContainer
>
  <div
    class="md:ml-8 md:mr-2 lg:mr-8 md:pr-4"
    [ngClass]="{
      'md:mb-24 md:pb-6': isStandalone$ | ngrxPush
    }"
  >
    <ea-calculation-parameters
      [isStandalone]="isStandalone$ | ngrxPush"
    ></ea-calculation-parameters>
  </div>

  <div class="hidden flex-row min-[1100px]:flex md:pr-4">
    <mat-divider [vertical]="true"></mat-divider>
    <div
      class="my-8 ml-8"
      [ngClass]="{
        'md:mb-32': isStandalone$ | ngrxPush
      }"
    >
      <ea-calculation-types-selection></ea-calculation-types-selection>
    </div>
  </div>
</div>
<ea-calculation-result-preview
  [sticky]="isResultPreviewSticky$ | ngrxPush"
  [class.hidden]="isKeyboardVisible$ | ngrxPush"
></ea-calculation-result-preview>

@if (isKeyboardVisible$ | ngrxPush) {
  <ea-calculation-indication-mobile
    [isCalculationLoading]="isAnyServiceLoading$ | ngrxPush"
    [isCalculationResultAvailable]="isCalculationResultAvailable$ | ngrxPush"
  ></ea-calculation-indication-mobile>
}
