<div
  class="text-medium-emphasis ml-8 md:flex md:min-w-[250px] h-full mb-auto flex-row hidden"
  *transloco="let t; read: 'calculationSelection'"
>
  <mat-divider [vertical]="true"></mat-divider>
  <div
    class="mb-2 ml-8 mr-4 w-full md:overflow-y-auto md:max-h-[calc(65vh-20px)] md:pr-4"
  >
    <div class="mb-4">
      <button
        mat-flat-button
        (click)="onDownloadAction()"
        [hidden]="isDownloadButtonHidden"
        [disabled]="isDownloadDisabled"
        class="text-center flex w-full flex-row items-center px-4 py-2 cursor-pointer text-title-small disabled:text-medium-emphasis disabled:hover:bg-transparent disabled:cursor-auto font-semibold leading-snug"
      >
        <mat-icon class="shrink-0 aspect-square">download</mat-icon>
        <span class="uppercase block w-full flex-grow">{{
          t('saveAsPDF')
        }}</span>
      </button>
    </div>

    <div class="mb-2">
      <div class="flex items-center justify-center">
        <mat-icon class="mr-2 flex-shrink-0">calculate</mat-icon>
        <div>{{ t('calculations') }}</div>
      </div>
    </div>
    <mat-divider></mat-divider>
    @if (calculationResultTypeSelection) {
      <div class="flex pt-4 flex-col">
        @for (config of calculationResultTypeSelection; track config) {
          <div
            (click)="onCalculationTypeClicked(config.name)"
            class="hover:bg-secondary-legacy-100 hover:text-primary mt-4 flex w-full flex-row items-center px-4 py-2 hover:rounded cursor-pointer"
          >
            <mat-icon class="mr-2 flex-shrink-0" [svgIcon]="config.svgIcon">{{
              config.icon
            }}</mat-icon>
            <div class="text-title-small whitespace-normal">
              {{ t(config.label) }}
            </div>
          </div>
        }
      </div>
    }
  </div>
</div>
