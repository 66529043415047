export const Greases: { label: string; value: `LB_${string}` }[] = [
  {
    value: 'LB_ARCANOL_CLEAN_M',
    label: 'Arcanol Clean M',
  },
  {
    value: 'LB_FAG_FOOD_2',
    label: 'Arcanol FOOD2',
  },
  {
    value: 'LB_FAG_LOAD_1000',
    label: 'Arcanol LOAD1000',
  },
  {
    value: 'LB_FAG_LOAD_150',
    label: 'Arcanol LOAD150',
  },
  {
    value: 'LB_FAG_LOAD_220',
    label: 'Arcanol LOAD220',
  },
  {
    value: 'LB_FAG_LOAD_400',
    label: 'Arcanol LOAD400',
  },
  {
    value: 'LB_FAG_LOAD_460',
    label: 'Arcanol LOAD460',
  },
  {
    value: 'LB_ARCANOL_MOTION2',
    label: 'Arcanol MOTION 2',
  },
  {
    value: 'LB_FAG_MULTI_2',
    label: 'Arcanol MULTI2',
  },
  {
    value: 'LB_FAG_MULTI_3',
    label: 'Arcanol MULTI3',
  },
  {
    value: 'LB_FAG_MULTITOP',
    label: 'Arcanol MULTITOP',
  },
  {
    value: 'LB_FAG_SPEED_2_6',
    label: 'Arcanol SPEED 2,6',
  },
  {
    value: 'LB_FAG_TEMP_110',
    label: 'Arcanol TEMP110',
  },
  {
    value: 'LB_FAG_TEMP_120',
    label: 'Arcanol TEMP120',
  },
  {
    value: 'LB_FAG_TEMP_200',
    label: 'Arcanol TEMP200',
  },
  {
    value: 'LB_FAG_TEMP_90',
    label: 'Arcanol TEMP90',
  },
  {
    value: 'LB_FAG_VIB_3',
    label: 'Arcanol VIB3',
  },
];
