<div class="border-border bg-surface-legacy rounded border mb-6 md:mb-0">
  <div
    class="border-border flex flex-row items-center border-b px-6 py-4 md:flex-nowrap flex-wrap-reverse"
  >
    @if (svgIcon || icon) {
      <mat-icon class="!text-medium-emphasis mr-2" [svgIcon]="svgIcon">{{
        icon
      }}</mat-icon>
    }
    <h5 class="text-title-small text-medium-emphasis mr-1 md:mr-4">
      {{ title }}
    </h5>
    @if (isMandatory) {
      <span
        class="text-body-small ml-auto text-low-emphasis font-medium tracking-wide"
        >{{ 'general.requiredInformation' | transloco }}</span
      >
    }
  </div>
  <div class="md:p-8 md:pb-4 px-2 py-4">
    <ng-content></ng-content>
  </div>
</div>
