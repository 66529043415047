<div
  class="border border-border md:bg-transparent rounded-lg flex flex-col gap-4 py-4 px-2 md:gap-6 md:px-6 md:pt-4 md:pb-8 bg-surface"
  [class.border-primary]="selected"
  [class.opacity-75]="isDisabled"
>
  <ea-radio-button
    [formControl]="control"
    [label]="label?.toUpperCase()"
    [value]="name"
    [isSubOption]="true"
    [isDisabled]="isDisabled"
  ></ea-radio-button>

  @if (selected) {
    <ng-content></ng-content>
  } @else {
    <div class="text-medium-emphasis text-body-2">{{ description }}</div>
  }
</div>
