<div mat-dialog-title class="!px-0">
  <div
    class="inline-flex w-full flex-row content-around justify-between pl-4 pb-3 pr-2"
  >
    <div class="my-auto text-h6 text-high-emphasis">
      {{ data.title }}
    </div>
    <div class="flex flex-row">
      <button mat-icon-button (click)="closeDialog()">
        <mat-icon class="text-medium-emphasis">close</mat-icon>
      </button>
    </div>
  </div>
</div>

<mat-dialog-content class="!px-4 pb-4">
  <div>
    {{ data.description }}
  </div>
</mat-dialog-content>

<mat-dialog-actions class="justify-end">
  <button mat-stroked-button (click)="closeDialog()" color="primary">
    {{ data.cancelActionText }}
  </button>
  <button mat-raised-button color="primary" class="" (click)="applyDialog()">
    {{ data.confirmActionText }}
  </button>
</mat-dialog-actions>
