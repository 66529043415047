<div
  class="flex w-full items-baseline gap-2"
  *transloco="let t; read: 'operationConditions.errors'"
>
  <mat-form-field
    appearance="outline"
    class="flex w-full grow-0 flex-col pb-3"
    [subscriptSizing]="'dynamic'"
  >
    @if (label) {
      <mat-label>{{ label }}</mat-label>
    }
    <input
      matInput
      type="number"
      inputmode="decimal"
      [formControl]="control"
      [placeholder]="placeholder"
      [step]="stepSize"
    />
    @if (unit) {
      <div class="relative" matSuffix>
        {{ unit }}
      </div>
    }
    @if (hint) {
      <mat-hint>{{ hint }}</mat-hint>
    }
    @if (control.hasError('max')) {
      <mat-error>{{ t('max', control.getError('max')) }}</mat-error>
    }
    @if (control.hasError('min')) {
      <mat-error>{{ t('min', control.getError('min')) }}</mat-error>
    }
    @if (control.hasError('required')) {
      <mat-error>
        {{ t('required') }}
      </mat-error>
    }
    @for (error of customErrors; track error) {
      <ng-container ngProjectAs="mat-error">
        @if (control.hasError(error.name)) {
          <mat-error>
            {{ t(error.message || error.name) }}
          </mat-error>
        }
      </ng-container>
    }
  </mat-form-field>
  @if (tooltip) {
    <div>
      <ea-info-button [tooltip]="tooltip"></ea-info-button>
    </div>
  }
</div>
