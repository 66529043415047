import { createReducer, on } from '@ngrx/store';

import { CO2DownstreamCalculationActions } from '../../actions';
import { DownstreamCalculationState } from '../../models';

export const initialState: DownstreamCalculationState = {
  isLoading: false,
  errors: [],
  warnings: [],
  notes: [],
};

export const co2downstreamCalculationReducer = createReducer(
  initialState,
  on(
    CO2DownstreamCalculationActions.resetDownstreamCalculation,
    (_state): DownstreamCalculationState => initialState
  ),
  on(
    CO2DownstreamCalculationActions.setDownstreamCalculationResult,
    (state, { result }): DownstreamCalculationState => {
      if (result.errors.length > 0) {
        return {
          ...state,
          errors: result.errors.flatMap((error) => error.message),
        };
      }

      const loadcases: { [key: string]: number } = {};

      for (const lcr of result.loadcaseResultData) {
        loadcases[`${lcr.idcO_DESIGNATION}`] =
          lcr.idcO_CO2_EMISSIONS_PER_LOAD_CASE;
      }

      return {
        ...state,
        errors: result.errors.flatMap((err) => err.message),
        co2Emissions: result.idcO_CO2_EMISSIONS,
        loadcaseEmissions: loadcases,
      };
    }
  )
);
