<div
  class="flex w-full flex-row items-baseline gap-2"
  *transloco="let t; read: 'inputs'"
>
  <mat-form-field
    appearance="outline"
    floatLabel="always"
    class="flex w-full grow-0 flex-row pb-3"
  >
    @if (label) {
      <mat-label>{{ label }}</mat-label>
    }
    <mat-select
      [formControl]="control"
      [placeholder]="placeholder || t('pleaseSelect')"
    >
      @for (option of options; track option) {
        <mat-option [value]="option.value">
          {{ option.label }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>

  @if (tooltip) {
    <div>
      <ea-info-button [tooltip]="tooltip"></ea-info-button>
    </div>
  }
</div>
